import * as React from "react";
import { Link } from "gatsby";
import "../styles/bootstrap.scss";

interface CallToActionProps {
  showContactLink: boolean;
  showDistributorLink: boolean;
}

const CallToActionWideWrapper: React.FC = ({ children }) => (
  <div className="container mt-5 py-5">
    <div className="row">
      <div className="col-lg-3"></div>
      <div className="col-lg-6">
        <div className="row">{children}</div>
      </div>
      <div className="col-lg-3"></div>
    </div>
  </div>
);

const CallToActionNarrowWrapper: React.FC = ({ children }) => (
  <div className="container mt-5 py-5">
    <div className="row">
      <div className="col-lg-4"></div>
      <div className="col-lg-4">
        <div className="row">{children}</div>
      </div>
      <div className="col-lg-4"></div>
    </div>
  </div>
);

const ContactCallToAction: React.FC = () => (
  <div className="mb-3">
    <Link to="/contact/" className="btn btn-primary btn-lg btn-block">
      Contact Us
    </Link>
  </div>
);

const DistributorsCallToAction: React.FC = () => (
  <div className="mb-3">
    <Link to="/distributors/" className="btn btn-primary btn-lg btn-block">
      Find a Distributor
    </Link>
  </div>
);

const CallToAction: React.FC<CallToActionProps> = ({
  showContactLink,
  showDistributorLink
}) => {
  if (showContactLink && showDistributorLink) {
    return (
      <CallToActionWideWrapper>
        <div className="col-sm-6">
          <ContactCallToAction />
        </div>
        <div className="col-sm-6">
          <DistributorsCallToAction />
        </div>
      </CallToActionWideWrapper>
    );
  }
  if (showContactLink) {
    return (
      <CallToActionNarrowWrapper>
        <div className="col">
          <ContactCallToAction />
        </div>
      </CallToActionNarrowWrapper>
    );
  }
  if (showDistributorLink) {
    return (
      <CallToActionNarrowWrapper>
        <div className="col">
          <DistributorsCallToAction />
        </div>
      </CallToActionNarrowWrapper>
    );
  }

  return null;
};

export default CallToAction;
