import * as React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import classnames from "classnames";
import { MenuItem, mainMenuItems } from "../data/menus";
import logo from "../assets/logo_white_on_red.svg";

interface NavbarProps {
  className?: string;
}

interface NavbarQueryProps {
  site: {
    siteMetadata: {
      title: string;
    };
  };
}

const NavbarWrapper: React.FC<NavbarProps> = ({ className }) => {
  const data: NavbarQueryProps = useStaticQuery(graphql`
    query NavbarQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const navItems = (menuItems: MenuItem[], level?: number) => {
    return menuItems.map((menuItem, index) => {
      if (level === 0) {
        if (menuItem.subMenu === undefined) {
          return (
            <Nav.Link key={`${level}-${index}`} href={menuItem.url}>
              {menuItem.label}
            </Nav.Link>
          );
        } else {
          return (
            <NavDropdown
              key={`${level}-${index}`}
              title={menuItem.label}
              id={`nav-dropdown-${level}-${index}`}
            >
              {navItems(menuItem.subMenu, level + 1)}
            </NavDropdown>
          );
        }
      } else {
        return (
          <NavDropdown.Item key={`${level}-${index}`} href={menuItem.url}>
            {menuItem.label}
          </NavDropdown.Item>
        );
      }
    });
  };

  return (
    <div
      className="navbar-wrapper bg-qgc-red"
      style={{
        zIndex: 1030
      }}
    >
      <div className="container px-0 py-1">
        <Navbar className={classnames("navbar-dark", className)} expand="lg">
          <Link to="/" className="navbar-brand">
            <img src={logo} alt={data.site.siteMetadata.title} height="52" />
          </Link>
          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Collapse
            id="navbar-nav"
            className="justify-content-end text-right"
          >
            <Nav>{navItems(mainMenuItems, 0)}</Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default NavbarWrapper;
