import * as React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import Root from "../components/Root";
import Navbar from "../components/Navbar";
import CallToAction from "../components/CallToAction";
import "../styles/theme/page.scss";
import "../styles/components/content.scss";

interface DefaultLayoutProps {
  pageTitle: string;
  className?: string;
  isFront?: boolean;
  isContact?: boolean;
  isDistributorIndex?: boolean;
}

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string;
      description: string;
      keywords: string;
    };
  };
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({
  children,
  className,
  isFront,
  isContact,
  isDistributorIndex,
  pageTitle
}) => (
  <StaticQuery
    query={graphql`
      query OverrideDefaultLayoutQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={(data: StaticQueryProps) => {
      const siteTitle = data.site.siteMetadata.title;
      return (
        <Root className={className}>
          <Helmet title={isFront ? siteTitle : `${pageTitle} | ${siteTitle}`}>
            {isContact && (
              <script
                src="https://www.google.com/recaptcha/api.js"
                async
                defer
              ></script>
            )}
          </Helmet>
          <div className="page d-flex flex-column">
            {!isFront && <Navbar />}
            <main className="main flex-grow-1">
              <div className="content">{children}</div>
              <CallToAction
                showContactLink={!isContact}
                showDistributorLink={!isDistributorIndex}
              />
            </main>
            <div className="footer mt-5 bg-qgc-red text-white">
              <div className="container py-3">
                <div className="">
                  <p className="h1">Quality Gourmet Chef</p>
                </div>
                <div className="d-lg-flex">
                  <div className="mr-3">3 Marker Avenue, Marleston SA 5033</div>
                  <div className="mr-3">
                    <strong>Ph:</strong>&nbsp;
                    <a className="text-white" href="fax:+61458778878">
                      0458 778 878
                    </a>
                  </div>
                  <div className="mr-3">
                    <strong>Email:</strong>&nbsp;
                    <a
                      className="text-white"
                      href="mailto:admin@qgourmetchef.com.au"
                    >
                      admin@qgourmetchef.com.au
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="boilerplate bg-qgc-red text-white">
              <div className="container text-center">
                <div className="py-3">
                  <p className="m-0">© 2020 {data.site.siteMetadata.title}</p>
                </div>
              </div>
            </div>
          </div>
        </Root>
      );
    }}
  />
);

export default DefaultLayout;
